$(document).foundation();
/**
 * -> foundation-equalizer
 * 		GD: I added a wrapper to not fire the watch if the window is less than 
 * 			800px, to fix (https://basecamp.com/1811372/projects/11630232/todos/278882457)
 */

$(document).ready(function() {
	$('.display-search').click(function() {
		var searchForm = $('.search-form');
		searchForm.toggleClass('hide');
		if (!searchForm.hasClass('hide')) {
			$('.search-query').focus();
		}
	});
	
	$(document).click(function(event) {
		if ($(event.target)[0] !== $('.display-search')[0] && $(event.target)[0] !== $('.search-query')[0]) {
			$('.search-form').addClass('hide');
		}
	});
});